<template>
  <div class="confirm">
    <div class="confirm-left-box">
      <div class="confirm-left g-scroll-hide">
        <m-page-title
          v-if="feeType === '1'"
          title="Choose your payment method"
        ></m-page-title>
        <div class="pay-list" v-if="feeType === '1'">
          <div class="items-box g-scroll-style">
            <el-radio
              v-for="item in modePayList"
              :key="item.key"
              v-model="modePayForm.modePay"
              :label="item.key"
              :disabled="item.disabled"
              name="modePay"
              class="mode-pay"
              @change="payTypeChange"
            >
              <div class="radio-box">
                <div class="radio-title">
                  <span class="radio-title-txt">{{ item.label }}</span>
                  <el-image
                    class="radio-title-img"
                    fit="contain"
                    :src="item.img"
                  />
                </div>
                <div class="radio-content">
                  {{ item.content }}
                </div>
              </div>
            </el-radio>
          </div>
          <el-form
            :model="modePayForm"
            :rules="modePayFormRules"
            ref="cardForm"
            v-if="modePayForm.modePay === 0"
          >
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item prop="cardNumber">
                  <el-input
                    class="pay-ipt"
                    v-model="modePayForm.cardNumber"
                    placeholder="Card number"
                    maxlength="16"
                    @input="($event) => numberInput($event, 'cardNumber')"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12">
                <el-form-item prop="expMonth">
                  <el-input
                    class="pay-ipt"
                    v-model="modePayForm.expMonth"
                    placeholder="Expires MM"
                    maxlength="2"
                    @input="($event) => numberInput($event, 'expMonth')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="expYear">
                  <el-input
                    class="pay-ipt"
                    v-model="modePayForm.expYear"
                    placeholder="Expires YY"
                    maxlength="2"
                    @input="($event) => numberInput($event, 'expYear')"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item prop="cvc">
                  <el-input
                    class="pay-ipt"
                    v-model="modePayForm.cvc"
                    placeholder="CSC"
                    maxlength="3"
                    @input="($event) => numberInput($event, 'cvc')"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <h4 class="confirm-left-ftitle">Billing address</h4>
        <el-form
          :model="confirmForm"
          :rules="confirmFormRules"
          ref="confirmForm"
          label-position="top"
          size="medium"
          @validate="confirmFormValid"
        >
          <el-form-item label="First Name" prop="firstName">
            <el-input v-model="confirmForm.firstName"></el-input>
          </el-form-item>
          <el-form-item label="Last Name" prop="lastName">
            <el-input v-model="confirmForm.lastName"></el-input>
          </el-form-item>
          <el-form-item label="Phone" prop="phone">
            <el-input v-model="confirmForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="Email" prop="email">
            <el-input v-model="confirmForm.email"></el-input>
          </el-form-item>
          <el-form-item label="Address" prop="address">
            <el-input v-model="confirmForm.address"></el-input>
          </el-form-item>
          <el-form-item prop="agreement">
            <el-checkbox v-model="confirmForm.agreement">
              <div class="agreement">
                By proceeding you agree to the
                <router-link target="_blank" :to="{ path: '/termsConditions' }">
                  Terms of use </router-link
                >and<router-link
                  target="_blank"
                  :to="{ path: '/privacyPolicy' }"
                  >Privacy policy</router-link
                >
              </div>
            </el-checkbox>
          </el-form-item>
          <div class="line flex-row margin-top">
            <m-back class="link" />
            <el-button
              v-show="modePayForm.modePay === 0 && feeType === '1'"
              class="action-btn"
              type="primary"
              @click="proceedClick"
              :loading="btnLoading"
              :disabled="btnLoading"
            >
              Make a payment
            </el-button>
            <div
              v-show="modePayForm.modePay === 7 && feeType === '1'"
              id="paypal-button-container"
              class="action-btn"
            ></div>
            <el-button
              v-show="feeType === '0'"
              class="action-btn"
              type="primary"
              @click="completeClick"
              :loading="btnLoading"
              :disabled="btnLoading"
            >
              Complete
            </el-button>
          </div>
        </el-form>
      </div>
    </div>
    <div class="confirm-right">
      <pay-total
        :option-type="1"
        :moneyList="moneyList"
        :total="orderDetails.totalAmt"
        :date="orderDetails.day"
        :time="`${dateVal(orderDetails.startTime)} - ${dateVal(
          orderDetails.endTime
        )}`"
        :apartment-id="orderDetails.apartmentId"
        :apartment-unit-id="orderDetails.apartmentUnitId"
      />
    </div>
    <m-dialog
      v-model="dialogShow"
      mask
      unBack
      cancelHide
      confirm-txt="Return to my booking"
      :title="dialogTitle"
      :content="successContent"
      :image="dialogImg"
      @confirm="confirmPopup"
    ></m-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { dateVal } from "@/utils/tools";
import asyncLoadJs from "@/plugins/loadResources";
import validateForm from "../../utils/validateForm";
import user from "@/utils/user";
import PayTotal from "@/components/PayTotal";
let loadedJs = false; //是否加载完js
//Paypal支付方式
// eslint-disable-next-line no-unused-vars
// const fundingSources = [
//   // eslint-disable-next-line no-undef
//   paypal.FUNDING.PAYPAL,
//   // paypal.FUNDING.VENMO,
//   // paypal.FUNDING.CREDIT,
//   // paypal.FUNDING.CARD
// ];
let payPalButtonActions;
export default {
  name: "Pay",
  components: { PayTotal },
  data() {
    let checkAgree = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("Please select this option"));
      }
      setTimeout(() => {
        if (!value) {
          callback(new Error("Please select this option"));
        } else {
          callback();
        }
      }, 1000);
    };
    return {
      orderId: undefined,
      successContent: undefined,
      feeType: undefined, //免费 or 付费
      optionType: undefined,
      dialogImg: undefined,
      orderDetails: {},
      orderData: {},
      fundingSources: [],
      // 金额 ? 用于传递给右侧组件
      moneyList: [
        {
          label: "Subtotal（GST Exclude）",
          value: "0",
        },
        {
          label: "GST",
          value: "0",
        },
        {
          label: "Surcharge",
          value: "0",
        },
      ],
      //支付方式
      modePayList: [
        {
          key: 7, //payPal
          label: "Paypal",
          content:
            "Safe payment online. Credit card needed. Paypal account is not necessary.",
          img: require("@/static/image/pay/paypal.png"),
          disabled: false,
        },
        {
          key: 0, //stripe
          label: "Credit Card",
          content:
            "Safe money transfer using your bank account.\n" +
            "Visa, mastercard,discover,american express.",
          img: require("@/static/image/pay/creditcard.png"),
          disabled: false,
        },
      ], // 支付方式列表
      modePayForm: {
        modePay: undefined, // 支付方式,0:stripe,7:paypal
        cardNumber: "", // 卡号
        expMonth: null,
        expYear: null,
        cvc: null,
      },
      payPalPaymentData: undefined,
      stripePaymentData: undefined,
      btnLoading: false,
      confirmForm: {
        firstName: undefined,
        lastName: undefined,
        phone: undefined,
        email: undefined,
        address: undefined,
        agreement: undefined,
      },
      confirmDataValid: {
        //验证信息
        firstName: undefined,
        lastName: undefined,
        phone: undefined,
        email: undefined,
        address: undefined,
        agreement: undefined,
      },
      dialogShow: false,
      modePayFormRules: {
        cardNumber: [
          {
            required: true,
            message: "Please enter card number",
            trigger: "blur",
          },
          {
            min: 16,
            max: 16,
            message: "The card number is 16 digits",
            trigger: "blur",
          },
        ],
        expMonth: [
          {
            required: true,
            message: "Please enter expires",
            trigger: "blur",
          },
          {
            min: 2,
            max: 2,
            message: "2 digits",
            trigger: "blur",
          },
        ],
        expYear: [
          {
            required: true,
            message: "Please enter expires",
            trigger: "blur",
          },
          {
            min: 2,
            max: 2,
            message: "2 digits",
            trigger: "blur",
          },
        ],
        cvc: [
          {
            required: true,
            message: "Please enter csc",
            trigger: "blur",
          },
          {
            min: 3,
            max: 3,
            message: "3 digits",
            trigger: "blur",
          },
        ],
      },
      confirmFormRules: {
        firstName: [
          {
            required: true,
            message: "Please enter first name",
            trigger: "blur",
          },
          {
            min: 2,
            max: 20,
            message: "Length should be 2 to 20",
            trigger: "blur",
          },
        ],
        lastName: [
          {
            required: true,
            message: "Please enter last name",
            trigger: "blur",
          },
          {
            min: 2,
            max: 20,
            message: "Length should be 2 to 20",
            trigger: "blur",
          },
        ],
        phone: [
          // {
          //   required: true,
          //   message: "Please enter phone",
          //   trigger: "blur",
          // },
          { validator: validateForm.phoneAllowNull, trigger: "blur" },
        ],
        email: [
          {
            required: true,
            message: "Please enter email address",
            trigger: "blur",
          },
          {
            min: 5,
            max: 100,
            message: "Length should be 5 to 100",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Please enter a valid email address",
            trigger: ["blur", "change"],
          },
        ],
        address: [
          {
            required: true,
            message: "Please enter address",
            trigger: "blur",
          },
          {
            min: 2,
            max: 1000,
            message: "Length should be 2 to 1000",
            trigger: "blur",
          },
        ],
        agreement: [{ validator: checkAgree, trigger: ["blur", "change"] }],
      },
    };
  },
  created() {
    this.feeType = this.$route.query.feeType;
    this.optionType = this.$route.query.optionType;
    this.orderId = this.$route.query.orderId;
    this.loadPaypal();
    this.setPaymentInfo();
    this.orderInfo();
    this.getBillingAddress();
    this.keyupSubmit();
  },
  mounted() {
    if (this.feeType === "1") {
      const that = this;
      let interval = setInterval(() => {
        if (loadedJs) {
          clearInterval(interval);
          // eslint-disable-next-line no-undef
          that.fundingSources.push(paypal.FUNDING.PAYPAL);
          that.initializePaypalButton();
        }
      }, 100);
    }
  },
  computed: {
    ...mapState("booking", ["modifyBookingData"]),
    dialogTitle() {
      if (this.feeType === "0") {
        return "Booking successful";
      } else if (this.feeType === "1") {
        if (this.optionType === "1") {
          return "Payment successful";
        } else if (this.optionType === "2") {
          return "Successfully rescheduled";
        }
      }
      return "";
    },
  },
  methods: {
    dateVal,
    ...mapMutations("bar", ["setSideBarStatus"]),
    // enter事件
    keyupSubmit() {
      document.onkeydown = () => {
        const _key = window.event.keyCode;
        if (_key === 13) {
          if (this.dialogShow) {
            this.confirmPopup();
            return;
          }
          if (this.feeType === "0" && !this.btnLoading) {
            this.completeClick();
            return;
          }
          if (
            this.modePayForm.modePay === 0 &&
            this.feeType === "1" &&
            !this.btnLoading
          ) {
            this.proceedClick();
            return;
          }
        }
      };
    },
    loadPaypal() {
      //获取payPal配置信息
      this.$axios.get(this.$api.payPalInfo).then((res) => {
        if (res.code === "1000") {
          const { clientId } = res.data;
          if (!loadedJs) {
            const url = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=AUD`;
            asyncLoadJs(url).then(() => {
              loadedJs = true;
            });
          }
        }
      });
    },
    backClick() {
      this.$router.back();
    },
    //初始化支付信息
    setPaymentInfo() {
      if (this.optionType === "2") {
        this.$set(this.orderDetails, "id", this.modifyBookingData.orderId);
        this.$set(this.orderDetails, "type", this.modifyBookingData.type);
        this.$set(
          this.orderDetails,
          "totalAmt",
          this.modifyBookingData.totalAmt
        );
        this.$set(this.orderDetails, "taxes", this.modifyBookingData.taxes);
        this.$set(
          this.orderDetails,
          "creditCardSurcharge",
          this.modifyBookingData.creditCardSurcharge
        );
        this.$set(this.orderDetails, "day", this.modifyBookingData.day);
        this.$set(
          this.orderDetails,
          "startTime",
          this.modifyBookingData.startTime
        );
        this.$set(this.orderDetails, "endTime", this.modifyBookingData.endTime);
        this.setMoneyList();
        this.orderData.orderId = this.modifyBookingData.orderId;
        this.orderData.day = this.modifyBookingData.day;
        this.orderData.startTime = this.modifyBookingData.startTime;
        this.orderData.endTime = this.modifyBookingData.endTime;
      }
    },
    //设置支付金额列表
    setMoneyList() {
      this.moneyList.splice(0, this.moneyList.length);
      this.moneyList.push({
        label: "Subtotal（GST Exclude）",
        value: (
          this.orderDetails.totalAmt -
          this.orderDetails.creditCardSurcharge -
          this.orderDetails.taxes
        )
          .toFixed(2)
          .toString(),
      });
      this.moneyList.push({
        label: "GST",
        value: this.orderDetails.taxes.toFixed(2).toString(),
      });
      this.moneyList.push({
        label: "Surcharge",
        value: this.orderDetails.creditCardSurcharge.toFixed(2).toString(),
      });
      if (this.orderDetails.totalAmt > 0) {
        if (this.optionType === "1") {
          this.successContent =
            "Thank you. The confirmation letter will be sent to your email shortly. Please check your junk mailbox if needed.";
          // this.successContent = `Thank you ! You have successfully paid $${
          //   this.orderDetails.totalAmt
          // } to Leon’s Property for ${this.getServiceName(
          //   this.orderDetails.type
          // )}. `;
        } else if (this.optionType === "2") {
          this.successContent =
            "Congratulation! You've successfully reschedule your appointment from " +
            this.modifyBookingData.preDay +
            " " +
            this.modifyBookingData.preStartTime +
            "-" +
            this.modifyBookingData.preEndTime +
            " to " +
            this.modifyBookingData.day +
            " " +
            this.modifyBookingData.startTime +
            "-" +
            this.modifyBookingData.endTime +
            "";
        }
      } else {
        this.successContent = `Thank you! You have successfully booked ${this.getServiceName(
          this.orderDetails.type
        )}.`;
      }
    },
    //设置费用类型
    setFeeType() {
      if (this.optionType === "1") {
        this.feeType = this.orderDetails.totalAmt === 0 ? "0" : "1";
      } else {
        this.feeType = this.modifyBookingData.totalAmt === 0 ? "0" : "1";
      }
    },
    //表单验证
    checkForm(formName) {
      return new Promise((resolve, reject) => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            resolve();
          } else reject();
        });
      });
    },
    // proceed点击事件
    async proceedClick() {
      const that = this;
      let list = [];
      list.push(this.checkForm("cardForm"));
      list.push(this.checkForm("confirmForm"));
      Promise.all(list)
        .then(() => {
          that.btnLoading = true;
          const { firstName, lastName } = that.confirmForm;
          that.confirmForm.fullName = `${firstName},${lastName},${firstName} ${lastName}`;
          if (that.optionType === "1") {
            that.stripePaymentData = {
              orderId: that.orderDetails.id,
              paymentAmt: that.orderDetails.totalAmt,
              ...that.modePayForm,
              ...that.confirmForm,
            };
            delete that.stripePaymentData.modePay;
            delete that.stripePaymentData.firstName;
            delete that.stripePaymentData.lastName;
          } else if (that.optionType === "2") {
            that.orderData = Object.assign(
              {},
              that.orderData,
              that.modePayForm,
              that.confirmForm
            );
            delete that.orderData.modePay;
            delete that.orderData.firstName;
            delete that.orderData.lastName;
          }
          that.stripePayment();
        })
        .catch(() => {
          console.log("error submit!");
          return false;
        });
    },
    //stripe 支付
    stripePayment() {
      let apiAddress = this.$api.stripePayment;
      let params = this.stripePaymentData;
      if (this.optionType === "2") {
        apiAddress = this.$api.updateOrder;
        params = this.orderData;
      }
      if (this.optionType === "1") {
        this.$axios
          .post(apiAddress, params)
          .then((res) => {
            this.btnLoading = false;
            this.stripePayAfter(res);
          })
          .catch(() => {
            this.btnLoading = false;
          });
      } else if (this.optionType === "2") {
        this.$axios
          .put(apiAddress, params)
          .then((res) => {
            this.btnLoading = false;
            this.stripePayAfter(res);
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    },
    stripePayAfter(res) {
      if (res.code === "1000") {
        const { isVerify, publishableKey, clientSecret, paymentIntentId } =
          res.data;
        if (isVerify) {
          this.stripeVerify(publishableKey, clientSecret, paymentIntentId);
        } else {
          this.dialogShow = true;
        }
      }
    },
    // stripe 3D验证
    stripeVerify(publishableKey, clientSecret, paymentIntentId) {
      const that = this;
      // eslint-disable-next-line no-undef
      this.stripe = Stripe(publishableKey);
      this.stripe.handleCardAction(clientSecret).then((res) => {
        if (res.error) {
          this.$notify.warning("Payment failed.");
          // Show error in payment form
          console.log("验证失败");
        } else {
          that.btnLoading = true;
          if (that.optionType === "1") {
            that.$set(
              this.stripePaymentData,
              "paymentIntentId",
              paymentIntentId
            );
          } else if (that.optionType === "2") {
            that.$set(this.orderData, "paymentIntentId", paymentIntentId);
          }
          that.stripePayment();
        }
      });
    },
    // 弹框确认
    confirmPopup() {
      this.setSideBarStatus();
      this.$router.push("/myBooking");
    },
    //创建Paypal支付按钮
    initializePaypalButton() {
      const that = this;
      let params, methodType;
      const token = user.getToken();
      let apiAddress = that.$api.config.baseURL;
      if (that.optionType === "1") {
        apiAddress += that.$api.payPalPayment;
        methodType = "post";
      } else if (that.optionType === "2") {
        apiAddress += that.$api.updateOrder;
        methodType = "put";
      }
      that.fundingSources.forEach(function (fundingSource) {
        // Initialize the buttons
        // eslint-disable-next-line no-undef
        const button = paypal.Buttons({
          fundingSource: fundingSource,
          style: {
            height: 34,
          },
          onInit: (data, actions) => {
            payPalButtonActions = actions;
            actions.disable();
          },
          createOrder: () => {
            const { firstName, lastName } = that.confirmForm;
            that.confirmForm.fullName = `${firstName},${lastName},${firstName} ${lastName}`;
            if (that.optionType === "1") {
              that.payPalPaymentData = {
                orderId: that.orderDetails.id,
                paymentAmt: that.orderDetails.totalAmt,
                ...that.confirmForm,
              };
              delete that.payPalPaymentData.firstName;
              delete that.payPalPaymentData.lastName;
              params = that.payPalPaymentData;
            } else if (that.optionType === "2") {
              that.orderData = Object.assign(
                {},
                that.orderData,
                that.confirmForm
              );
              delete that.orderData.firstName;
              delete that.orderData.lastName;
              params = that.orderData;
            }
            return fetch(apiAddress, {
              method: methodType,
              headers: {
                "content-type": "application/json",
                authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(params),
            })
              .then(function (res) {
                return res.json();
              })
              .then(function (data) {
                return data.data.payPalOrderId;
              });
          },
          onApprove: (data) => {
            that.$set(params, "payPalOrderId", data.orderID);
            return fetch(apiAddress, {
              method: methodType,
              headers: {
                "content-type": "application/json",
                authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(params),
            })
              .then(function (res) {
                return res.json();
              })
              .then(function (details) {
                that.dialogShow = true;
                console.log(details);
                console.log("支付成功");
              });
          },
        });
        if (button.isEligible()) {
          button.render("#paypal-button-container");
        }
      });
    },
    orderInfo() {
      //获取订单详情
      this.$axios.get(this.$api.orderInfo(this.orderId)).then((res) => {
        if (res.code === "1000") {
          const orderInfo = res.data.apartmentOrder;
          this.modePayForm.modePay = orderInfo.paymentMethod;
          if (this.optionType === "1") {
            //booking
            this.orderDetails = { ...orderInfo };
            this.setFeeType();
            this.setMoneyList();
          } else {
            const valueKeys = Object.keys(orderInfo);
            const changeKeys = Object.keys(this.orderDetails);
            valueKeys.forEach((key) => {
              if (!changeKeys.includes(key)) {
                this.$set(this.orderDetails, key, orderInfo[key]);
              }
            });
            //补差价只能用原支付方式
            if (0 <= this.orderDetails.paymentMethod) {
              const index = this.modePayList.findIndex(
                (item) => item.key !== this.orderDetails.paymentMethod
              );
              this.$set(this.modePayList[index], "disabled", true);
            }
          }
          this.getApartmentIno(this.orderDetails.apartmentId);
        }
      });
    },
    //服务类型
    getServiceName(bookingType) {
      switch (bookingType) {
        case 0:
          return "Move in service";
        case 1:
          return "Move out service";
        default:
          return "";
      }
    },
    //验证Billing address是否输入值
    confirmFormValid(key, result) {
      this.$set(this.confirmDataValid, key, result);
      this.validBillsData();
    },
    validBillsData() {
      if (
        this.confirmDataValid.firstName &&
        this.confirmDataValid.lastName &&
        this.confirmDataValid.phone &&
        this.confirmDataValid.email &&
        this.confirmDataValid.address &&
        this.confirmDataValid.agreement
      ) {
        payPalButtonActions?.enable();
      } else {
        payPalButtonActions?.disable();
      }
    },
    payTypeChange() {
      if (this.modePayForm.modePay === 7) {
        this.$refs.confirmForm.validate();
      }
    },
    //订单完成
    completeClick() {
      this.$refs.confirmForm.validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          this.$axios
            .put(
              this.$api.modifyOrderPart(this.orderDetails.id),
              this.confirmForm
            )
            .then((res) => {
              this.btnLoading = false;
              if (res.code === "1000") {
                this.dialogShow = true;
              }
            })
            .catch(() => {
              this.btnLoading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getApartmentIno(apartmentId) {
      this.$axios.get(this.$api.getApartmentIno(apartmentId)).then((res) => {
        if (res.code === "1000") {
          this.dialogImg = res.data.photo;
        }
      });
    },
    //查询发票地址信息
    getBillingAddress() {
      this.$axios.get(this.$api.billingAddress).then((res) => {
        if (res.code === "1000") {
          const addressInfo = res.data.invoice;
          const [firstName, lastName] = addressInfo.fullName.split(",");
          addressInfo.firstName = firstName;
          addressInfo.lastName = lastName;
          Object.keys(addressInfo).forEach((key) => {
            this.$set(this.confirmForm, key, addressInfo[key]);
            this.$set(this.confirmDataValid, key, !!addressInfo[key]);
          });
          this.validBillsData();
        }
      });
    },
    numberInput(e, key) {
      const arr = e.match(/\d/g);
      this.modePayForm[key] = arr ? arr.join("") : "";
    },
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
