const Q = require("q");
export default function asyncLoadJs(url) {
  return Q.Promise((resolve, reject) => {
    let srcArr = document.getElementsByTagName("script");
    let hasLoaded = false;
    for (let i = 0; i < srcArr.length; i++) {
      //判断当前js是否加载上
      hasLoaded = srcArr[i].src == url ? true : false;
    }
    if (hasLoaded) {
      resolve();
      return;
    }
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      resolve();
    };
    script.onerror = () => {
      reject();
    };
  });
}

//加载css
export function loadCss(url) {
  let css = document.createElement("link");
  css.href = url;
  css.rel = "stylesheet";
  css.type = "text/css";
  document.head.appendChild(css);
}
