import { regex } from "@/utils/tools";

/**
 * 统一处理简单验证
 * @param callback
 * @param bool {Boolean} 是否通过
 * @param msg {String} 提示语
 */
function validate(callback, bool, msg = "error") {
  if (bool) callback();
  else callback(new Error(msg));
}

export default {
  username: (rule, value, callback) =>
    validate(
      callback,
      regex.email(value),
      "Please enter a valid email address"
    ),
  phone: (rule, value, callback) =>
    validate(callback, regex.phone(value), "Please enter a valid phone number"),
  phoneAllowNull: function (rule, value, callback) {
    if (value) {
      validate(
        callback,
        regex.phone(value),
        "Please enter a valid phone number"
      );
    } else {
      callback();
    }
  },
  email: (rule, value, callback) =>
    validate(
      callback,
      regex.email(value),
      "Please enter a valid email address"
    ),
  agreementConfirm: (rule, value, callback) =>
    validate(callback, value, "Please check this box if you want to proceed"),
  name: (rule, value, callback) =>
    validate(
      callback,
      /^[A-Za-z][A-Za-z ]*[A-Za-z]$/.test(value),
      "Usernames can only contain letters, and must be at least 2 characters long"
    ),
};
